import * as React from 'react';

function SvgSoundcloud(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.16 11.442c-.223 0-.442.024-.652.069a5.635 5.635 0 00-5.599-5.07.625.625 0 00-.625.626v10c0 .345.28.625.625.625h6.25a3.128 3.128 0 003.125-3.125 3.129 3.129 0 00-3.125-3.125zM10.41 7.692a.625.625 0 00-.625.625v8.75a.625.625 0 001.25 0v-8.75a.625.625 0 00-.626-.625zM7.91 10.192a.625.625 0 00-.625.625v6.25a.625.625 0 001.25 0v-6.25a.625.625 0 00-.625-.625zM5.41 10.192a.625.625 0 00-.626.625v6.25a.625.625 0 001.25 0v-6.25a.625.625 0 00-.625-.625zM2.91 12.067a.625.625 0 00-.626.625v3.75a.625.625 0 001.25 0v-3.75a.625.625 0 00-.625-.625z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSoundcloud;
