import * as React from 'react';

function SvgViddler(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.728 4.289c-2.426 0-4.431 1.772-4.774 4.08h-.156c-.344-2.308-2.35-4.08-4.776-4.08-2.667 0-4.828 2.143-4.828 4.786 0 2.619 2.121 4.747 4.755 4.786v.001h.145v1.632l-5.537-1.488v6.366l5.537-1.65v1.817h8.139l-2.385-6.677h3.879c2.667 0 4.83-2.143 4.83-4.787 0-2.643-2.163-4.786-4.83-4.786zm-9.706 6.137a1.357 1.357 0 01-1.364-1.352c0-.747.611-1.352 1.364-1.352.752 0 1.363.605 1.363 1.352 0 .748-.61 1.352-1.363 1.352zm9.802.003a1.358 1.358 0 01-1.361-1.354c0-.746.609-1.351 1.361-1.351.755 0 1.366.605 1.366 1.35a1.36 1.36 0 01-1.366 1.355z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgViddler;
