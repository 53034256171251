import * as React from 'react';

function SvgSnapchat(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.608 16.935c-2.674-.47-3.879-3.39-3.928-3.515-.003-.008-.006-.016-.011-.024-.136-.296-.169-.54-.094-.725.146-.365.707-.555 1.077-.68.107-.036.208-.07.292-.105.365-.154.625-.32.798-.508.205-.226.245-.442.242-.582-.006-.341-.251-.645-.642-.793a1.098 1.098 0 00-.435-.088.916.916 0 00-.405.088c-.313.156-.593.241-.834.253a.725.725 0 01-.276-.064l.023-.412.004-.061c.086-1.444.192-3.24-.255-4.307a5.811 5.811 0 00-1.224-1.868 5.104 5.104 0 00-1.464-1.03A5.472 5.472 0 0012.192 2h-.05l-.343.004c-.444 0-1.333.067-2.287.515a5.076 5.076 0 00-1.458 1.028 5.83 5.83 0 00-1.22 1.864c-.447 1.065-.34 2.861-.255 4.304v.002l.027.474a.758.758 0 01-.328.065c-.252 0-.552-.085-.89-.255a.758.758 0 00-.34-.074c-.2 0-.415.062-.598.178-.233.146-.383.35-.424.578-.026.15-.025.447.285.748.171.165.421.319.745.455.085.036.186.069.292.105.37.125.932.315 1.078.68.074.185.041.43-.095.725a7.149 7.149 0 01-1.018 1.685c-.37.46-.78.846-1.215 1.145a4.312 4.312 0 01-1.706.707.492.492 0 00-.391.52c.004.074.02.147.049.218v.001c.096.239.317.44.677.617.44.218 1.098.4 1.956.543.044.088.09.308.12.459a7 7 0 00.115.5c.053.19.188.416.537.416.132 0 .283-.032.46-.068.257-.053.61-.127 1.049-.127.243 0 .496.023.75.068.49.087.912.404 1.4.772C9.83 21.391 10.64 22 11.878 22c.034 0 .068-.001.102-.004.04.003.091.004.144.004 1.237 0 2.047-.61 2.762-1.146l.001-.002c.49-.367.912-.684 1.402-.77.253-.046.506-.069.75-.069.42 0 .752.058 1.049.12.193.04.343.06.46.06h.022c.255 0 .443-.15.514-.41.047-.172.081-.332.115-.496.029-.142.076-.368.119-.456.858-.144 1.516-.325 1.956-.543.359-.177.58-.378.676-.616a.666.666 0 00.05-.22.49.49 0 00-.391-.517z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSnapchat;
