import * as React from 'react';

function SvgFoursquare(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.389 1.856H6.844c-1.45 0-1.882 1.109-1.882 1.806v16.945c0 .835.42 1.082.65 1.178.09.037.24.071.414.071.269 0 .595-.08.859-.345l5.09-5.995c.081-.096.121-.096.176-.096h3.168c1.353 0 1.61-.945 1.76-1.519.12-.479 1.516-7.323 1.948-9.485.324-1.67-.096-2.56-1.638-2.56zm-1.05 3.242l-.382 1.88a.628.628 0 01-.612.503h-3.508a.625.625 0 00-.625.625v1.25c0 .345.28.625.625.625h2.74c.397 0 .692.367.612.755l-.352 1.696a.613.613 0 01-.61.488H12.15c-.82 0-1.54.338-2.083.979l-2.605 3.068V4.981c0-.345.28-.625.625-.625h7.638a.62.62 0 01.614.742z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgFoursquare;
