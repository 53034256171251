import * as React from 'react';

function SvgTumblr(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.877 18.74c-.555 0-1.075-.13-1.505-.392-.343-.2-.646-.54-.766-.864-.12-.33-.107-1.005-.107-2.16v-4.58h5.004V7h-5.004V2h-3.224c-.13 1.029-.364 1.882-.707 2.543A5.538 5.538 0 018.23 6.264c-.565.476-1.44.84-2.23 1.099v2.833h2.684v7.01c0 .918.092 1.62.29 2.098.194.483.525.936 1.031 1.361.497.43 1.092.76 1.804.993.692.229 1.237.341 2.154.341a9.43 9.43 0 002.242-.245 11.202 11.202 0 002.298-.795v-3.006c-1.006.656-1.612.786-2.626.786z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTumblr;
