import * as React from 'react';

function SvgWhatsapp(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.003 2h-.005C6.484 2 2 6.485 2 12c0 2.188.705 4.215 1.904 5.861l-1.246 3.715L6.5 20.347A9.913 9.913 0 0012.003 22C17.516 22 22 17.514 22 12S17.516 2 12.003 2zm5.818 14.121c-.24.681-1.198 1.247-1.962 1.412-.523.11-1.205.2-3.503-.753-2.938-1.218-4.83-4.204-4.978-4.398-.142-.193-1.188-1.58-1.188-3.016 0-1.435.729-2.133 1.023-2.433.24-.247.64-.36 1.022-.36.124 0 .235.007.335.012.294.013.441.03.635.494.241.581.829 2.016.899 2.163.071.148.142.348.042.542-.093.2-.176.288-.323.458-.148.17-.288.3-.435.483-.135.159-.288.329-.118.623.17.287.758 1.246 1.623 2.016 1.116.993 2.02 1.311 2.345 1.446.24.1.528.076.705-.111.223-.242.5-.642.78-1.035.2-.283.453-.318.718-.218.27.094 1.7.8 1.993.947.294.147.487.217.559.34.07.124.07.706-.172 1.388z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgWhatsapp;
