import * as React from 'react';

function SvgDribbble(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18.75c-4.825 0-8.75-3.926-8.75-8.75 0-4.825 3.925-8.75 8.75-8.75 4.824 0 8.75 3.925 8.75 8.75 0 4.824-3.926 8.75-8.75 8.75z"
        fill="currentColor"
      />
      <path
        d="M12 4.5c-.525 0-1.037.055-1.533.158a35.626 35.626 0 012.464 4.023 17.65 17.65 0 003.854-2.456A7.468 7.468 0 0012 4.5zM17.676 7.108a19.031 19.031 0 01-4.203 2.685c.328.684.633 1.378.916 2.083a13.975 13.975 0 015.1-.088 7.457 7.457 0 00-1.813-4.68zM9.212 5.041A7.504 7.504 0 004.7 10.31c2.427.104 4.83-.301 7.066-1.155A34.185 34.185 0 009.212 5.04zM12.325 10.286a18.816 18.816 0 01-6.829 1.304c-.324 0-.649-.02-.974-.037-.008.148-.022.296-.022.447 0 1.841.666 3.526 1.768 4.831a14.062 14.062 0 016.902-4.643 33.429 33.429 0 00-.845-1.902zM14.848 13.06a35.32 35.32 0 011.406 5.114 7.49 7.49 0 003.166-5.135 12.743 12.743 0 00-4.572.021zM13.62 13.35a12.8 12.8 0 00-6.465 4.37 7.464 7.464 0 007.954 1.099 34.013 34.013 0 00-1.489-5.469z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDribbble;
