import * as React from 'react';

function SvgSpotify(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm4.588 14.435a.624.624 0 01-.87.152c-2.344-1.647-6.243-1.738-8.875-1.124a.625.625 0 01-.283-1.22c2.894-.67 7.219-.547 9.875 1.322.283.199.35.588.153.87zm1.22-2.439a.624.624 0 01-.873.143c-2.719-1.951-6.059-2.436-10.516-1.526a.624.624 0 11-.251-1.225c4.82-.984 8.473-.434 11.496 1.735.28.202.345.592.144.873zm1.232-3.275a.624.624 0 01-.869.166c-3.446-2.338-8.685-2.35-12.076-1.15a.625.625 0 11-.416-1.18c3.689-1.303 9.405-1.276 13.196 1.295a.627.627 0 01.165.87z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSpotify;
