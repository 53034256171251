import * as React from 'react';

function SvgVk(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.822 16.178c-.054-.09-.386-.812-1.986-2.297-1.676-1.555-1.45-1.303.567-3.992 1.229-1.637 1.72-2.638 1.566-3.065-.146-.408-1.05-.3-1.05-.3l-3.005.017s-.222-.03-.389.07c-.161.097-.265.322-.265.322S16.784 8.2 16.15 9.278c-1.339 2.274-1.875 2.394-2.094 2.252-.509-.328-.381-1.322-.381-2.027 0-2.204.334-3.123-.651-3.36-.328-.08-.568-.132-1.404-.14-1.073-.012-1.982.003-2.496.255-.342.167-.606.54-.445.562.2.026.65.121.888.446.309.42.297 1.362.297 1.362s.178 2.594-.413 2.916c-.407.221-.963-.23-2.157-2.294C6.682 8.194 6.22 7.025 6.22 7.025s-.088-.217-.247-.334c-.193-.14-.463-.186-.463-.186l-2.857.018s-.43.012-.587.198c-.14.167-.01.51-.01.51s2.237 5.233 4.77 7.872c2.323 2.42 4.961 2.26 4.961 2.26h1.195s.361-.04.545-.238c.17-.183.164-.525.164-.525s-.024-1.604.721-1.84c.734-.233 1.677 1.55 2.676 2.236.755.519 1.328.405 1.328.405l2.672-.038s1.397-.086.735-1.185z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgVk;
