import * as React from 'react';

function SvgBehance(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.228 11.424c.415-.2.729-.424.944-.665.383-.434.571-1.01.571-1.724 0-.695-.188-1.289-.566-1.787-.63-.811-1.696-1.226-3.204-1.248H2v11.704h5.57a9.06 9.06 0 001.746-.158 3.507 3.507 0 001.396-.588c.35-.248.643-.556.875-.92.368-.55.551-1.172.551-1.866 0-.672-.16-1.244-.481-1.715-.324-.47-.799-.815-1.429-1.033zM4.464 8.033h2.69c.591 0 1.079.061 1.462.182.442.177.663.535.663 1.083 0 .49-.168.834-.502 1.027-.336.193-.773.29-1.308.29H4.464V8.033zm4.254 7.434c-.297.138-.716.206-1.253.206H4.464v-3.121h3.043c.53.004.942.07 1.237.197.525.228.787.645.787 1.256 0 .72-.27 1.205-.813 1.462zM20.078 6.546H14.78v1.457h5.297V6.546zM21.918 12.295a4.08 4.08 0 00-.73-1.789 3.646 3.646 0 00-1.578-1.28c-.636-.272-1.352-.407-2.15-.406-1.339 0-2.426.402-3.268 1.2-.838.8-1.258 1.951-1.258 3.451 0 1.6.463 2.755 1.396 3.465.929.71 2.001 1.064 3.219 1.064 1.474 0 2.62-.421 3.439-1.261.524-.53.82-1.053.885-1.565h-2.44a1.904 1.904 0 01-.493.595c-.34.264-.782.396-1.325.396-.516 0-.954-.109-1.319-.326-.602-.348-.917-.958-.957-1.825h6.659c.01-.748-.015-1.323-.08-1.719zm-6.52.225c.088-.563.3-1.01.638-1.34.338-.328.815-.494 1.427-.495.563 0 1.035.155 1.418.465.38.314.593.768.637 1.37h-4.12z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBehance;
