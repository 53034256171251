import * as React from 'react';

function SvgShare(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47c.03-.246.03-.494 0-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgShare;
