import * as React from 'react';

function SvgReddit(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.845 13.23v.001h.622l-.622-.001zM15.068 13.23v.001h.622l-.622-.001z"
        fill="currentColor"
      />
      <path
        d="M22 11.385c0-1.358-1.116-2.462-2.49-2.462-.548 0-1.07.177-1.495.499-1.389-.954-3.209-1.523-5.144-1.667l1.044-2.422 3.038.702c.097.929.884 1.657 1.849 1.657 1.029 0 1.866-.828 1.866-1.846A1.859 1.859 0 0018.802 4c-.653 0-1.226.334-1.56.837l-3.55-.82a.624.624 0 00-.716.357l-1.444 3.348c-2.09.071-4.08.654-5.584 1.668-.41-.3-.918-.467-1.459-.467C3.116 8.923 2 10.027 2 11.385c0 .9.497 1.713 1.258 2.13-.01.108-.013.22-.013.331C3.245 17.24 7.152 20 11.956 20c4.803 0 8.712-2.76 8.712-6.154 0-.096-.003-.19-.01-.285A2.435 2.435 0 0022 11.385zM18.802 5.23a.62.62 0 01.622.615.62.62 0 01-.622.616.62.62 0 01-.623-.616c0-.34.28-.615.623-.615zm-11.201 8c0-.678.558-1.23 1.244-1.23a1.24 1.24 0 011.245 1.23c0 .68-.56 1.23-1.245 1.23a1.239 1.239 0 01-1.245-1.23zm7.23 4.082a4.936 4.936 0 01-5.748 0 .61.61 0 01-.14-.859.629.629 0 01.87-.138 3.683 3.683 0 004.288 0 .626.626 0 01.868.138.611.611 0 01-.139.86zm.237-2.851a1.238 1.238 0 01-1.245-1.231c0-.678.558-1.23 1.245-1.23s1.244.552 1.244 1.23c0 .68-.557 1.23-1.244 1.23z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgReddit;
