import * as React from 'react';

function SvgTwitter(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 5.894a8.653 8.653 0 01-2.363.638A4.026 4.026 0 0021.441 4.3a8.27 8.27 0 01-2.6.978A4.139 4.139 0 0015.846 4c-2.27 0-4.097 1.814-4.097 4.038 0 .32.027.628.095.92a11.666 11.666 0 01-8.451-4.222 4.017 4.017 0 00-.562 2.04c0 1.399.732 2.638 1.821 3.356A4.098 4.098 0 012.8 9.634v.045c0 1.962 1.421 3.591 3.285 3.966a4.15 4.15 0 01-1.075.133c-.263 0-.527-.014-.776-.069a4.131 4.131 0 003.831 2.813 8.32 8.32 0 01-5.084 1.722c-.336 0-.659-.015-.981-.056A11.675 11.675 0 008.29 20c7.545 0 11.67-6.154 11.67-11.488 0-.178-.006-.35-.015-.522A8.11 8.11 0 0022 5.894z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgTwitter;
