import * as React from 'react';

function SvgBlur(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.343 19.52l.707-.706-.707.707zm0-11.313l.707.707-.707-.707zM12 2.55l.707-.707a1 1 0 00-1.414 0L12 2.55zm5.657 5.657l.707-.707-.707.707zm0 11.314l.707.707-.707-.707zM8.343 12.5a1 1 0 10-2 0h2zm4.314 7a1 1 0 100-2v2zm-5.607-.686a7 7 0 010-9.9L5.636 7.5a9 9 0 000 12.728l1.414-1.414zm0-9.9l5.657-5.657-1.414-1.414L5.636 7.5 7.05 8.914zm4.243-5.657l5.657 5.657L18.364 7.5l-5.657-5.657-1.414 1.414zm5.657 5.657a7 7 0 010 9.9l1.414 1.414a9 9 0 000-12.728L16.95 8.914zm0 9.9a7 7 0 01-9.9 0l-1.414 1.414a9 9 0 0012.728 0l-1.414-1.414zM12.657 17.5c-1.12 0-1.902-.27-2.458-.634-.561-.367-.957-.872-1.237-1.436-.577-1.16-.619-2.464-.619-2.93h-2c0 .534.037 2.23.828 3.82.403.811 1.014 1.618 1.932 2.22.924.604 2.096.96 3.554.96v-2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBlur;
