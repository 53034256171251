import * as React from 'react';

function SvgSkype(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.425 14.123c.145-.652.22-1.327.22-2.02 0-5.193-4.27-9.404-9.539-9.404-.555 0-1.1.047-1.631.137A5.567 5.567 0 007.533 2C4.476 2 2 4.441 2 7.455c0 1.006.277 1.948.76 2.759a9.32 9.32 0 00-.194 1.89c0 5.195 4.272 9.406 9.539 9.406.598 0 1.181-.055 1.747-.157A5.58 5.58 0 0016.47 22C19.524 22 22 19.558 22 16.546c0-.87-.207-1.692-.575-2.423zm-4.426 2.476c-.441.616-1.093 1.104-1.939 1.449-.836.343-1.838.518-2.98.518-1.37 0-2.519-.237-3.416-.707a4.434 4.434 0 01-1.57-1.375c-.407-.575-.61-1.146-.61-1.699 0-.344.133-.642.397-.886.261-.244.596-.365.997-.365.327 0 .61.096.84.287.218.184.407.457.558.805.169.383.353.705.546.957.19.246.458.45.802.612.346.16.812.244 1.385.244.788 0 1.436-.166 1.921-.494.476-.319.706-.704.706-1.174 0-.37-.121-.662-.367-.89-.26-.24-.603-.427-1.018-.556a20.234 20.234 0 00-1.75-.427c-.991-.21-1.832-.457-2.5-.738-.683-.287-1.236-.685-1.638-1.182-.41-.507-.618-1.14-.618-1.885 0-.71.218-1.35.647-1.903.427-.549 1.05-.977 1.853-1.269.791-.288 1.732-.435 2.799-.435.851 0 1.6.098 2.226.29.629.193 1.159.453 1.575.774.42.325.732.669.928 1.029.197.362.298.724.298 1.072 0 .337-.131.643-.391.908a1.32 1.32 0 01-.98.404c-.352 0-.63-.087-.821-.253-.179-.156-.365-.4-.57-.747-.238-.445-.526-.797-.856-1.045-.322-.242-.857-.362-1.594-.362-.683 0-1.24.135-1.653.403-.397.256-.591.551-.591.901 0 .214.063.393.191.546.135.165.327.307.569.427.25.123.509.223.768.293.264.073.708.182 1.32.32.772.163 1.48.347 2.108.545a6.758 6.758 0 011.634.734c.456.294.818.669 1.075 1.119.258.454.387 1.01.387 1.657A3.54 3.54 0 0117 16.6z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgSkype;
