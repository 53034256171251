import * as React from 'react';

function SvgGithub(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.999 2C6.477 2 2 6.59 2 12.253c0 4.53 2.865 8.372 6.84 9.73.5.094.683-.223.683-.495 0-.244-.01-.888-.014-1.744-2.782.62-3.369-1.375-3.369-1.375-.454-1.184-1.11-1.5-1.11-1.5-.909-.635.067-.622.067-.622 1.004.072 1.532 1.057 1.532 1.057.892 1.566 2.341 1.114 2.911.851.09-.663.349-1.114.635-1.371-2.22-.26-4.555-1.139-4.555-5.067 0-1.12.39-2.035 1.03-2.752-.104-.26-.446-1.302.097-2.713 0 0 .84-.275 2.75 1.05a9.355 9.355 0 012.504-.344 9.377 9.377 0 012.504.346c1.909-1.326 2.748-1.05 2.748-1.05.544 1.411.202 2.453.1 2.712.64.718 1.027 1.633 1.027 2.753 0 3.938-2.337 4.805-4.565 5.059.359.316.679.942.679 1.898 0 1.37-.013 2.476-.013 2.812 0 .275.18.594.688.493C19.137 20.623 22 16.78 22 12.253 22 6.591 17.523 2 11.999 2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgGithub;
