import * as React from 'react';

function SvgBlogger(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.49 9.738c-.422-.18-2.234.02-2.736-.434-.355-.328-.378-.92-.517-1.71-.232-1.324-.328-1.624-.57-2.145C16.79 3.59 14.684 2 12.771 2H8.337C4.85 2 2 4.846 2 8.321v7.37C2 19.16 4.85 22 8.338 22h7.283c3.488 0 6.319-2.84 6.339-6.309l.04-5.104s0-.63-.51-.85zM8.418 7.166h3.514c.67 0 1.214.541 1.214 1.203s-.544 1.209-1.214 1.209H8.418c-.67 0-1.214-.548-1.214-1.21a1.21 1.21 0 011.214-1.202zm7.143 9.635H8.418c-.67 0-1.214-.547-1.214-1.202a1.21 1.21 0 011.214-1.203h7.143c.664 0 1.208.541 1.208 1.203 0 .655-.544 1.202-1.208 1.202z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgBlogger;
