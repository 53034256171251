import * as React from 'react';

function SvgYoutube(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.15 6.35c-.542-.983-1.131-1.164-2.33-1.233C17.622 5.034 14.611 5 12.002 5c-2.613 0-5.626.034-6.822.116-1.196.07-1.786.25-2.334 1.233C2.288 7.33 2 9.021 2 11.996v.01c0 2.963.288 4.666.846 5.637.548.983 1.136 1.161 2.333 1.244 1.197.071 4.21.113 6.823.113 2.61 0 5.62-.042 6.82-.112 1.198-.083 1.787-.26 2.33-1.244.563-.97.848-2.673.848-5.636V12v-.003c0-2.977-.285-4.668-.85-5.649zM9.5 15.817V8.182L15.75 12 9.5 15.818z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgYoutube;
