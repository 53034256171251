import * as React from 'react';

function SvgWordpress(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 1.25c1.766 0 3.409.53 4.785 1.434-.479.266-.922.826-.922 2.016 0 .633.43 1.366.93 2.216.598 1.02 1.34 2.29 1.155 3.205-.206.607-.726 1.983-1.29 3.452l-2.583-7.425c.053-.014.111-.023.161-.04a.624.624 0 10-.396-1.184c-.533.177-1.496.174-2.075.172h-.347c-.575.002-1.543.005-2.075-.172a.624.624 0 00-.394 1.185c.264.089.575.136.892.171l1.538 3.998-1.454 3.667-2.484-7.77c.091-.021.189-.037.273-.066a.623.623 0 10-.395-1.185c-.532.177-1.498.172-2.077.172h-.17c-.113 0-.206-.008-.3-.016A8.743 8.743 0 0112 3.25zM3.25 12c0-.982.17-1.925.47-2.807l3.835 10.33C4.984 17.999 3.25 15.202 3.25 12zM12 20.75a8.751 8.751 0 01-2.489-.365L12.041 14l2.455 6.384A8.756 8.756 0 0112 20.75zm4.474-1.245c.81-2.076 2.205-5.672 2.623-6.889l.017.005c.017-.05.036-.115.049-.19.14-.393.26-.752.357-1.055.309-.976.505-1.835.608-2.595a8.67 8.67 0 01.622 3.22c0 3.188-1.72 5.975-4.276 7.504z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgWordpress;
