import * as React from 'react';

function SvgDribble(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 11.955v.09c.01 2.685.984 5.144 2.592 7.048a11.066 11.066 0 003.378 2.692A10.922 10.922 0 0012 23c3.69 0 6.955-1.816 8.95-4.604A10.96 10.96 0 0023 12c0-3.26-1.418-6.19-3.672-8.203a10.949 10.949 0 00-7.663-2.792A10.944 10.944 0 004.43 4.019 11.05 11.05 0 001.67 8.207 10.976 10.976 0 001 11.955zm19.481 3.064c.336-.944.519-1.96.519-3.019a8.971 8.971 0 00-2.581-6.309 10.93 10.93 0 01-3.152 3.356c.468 1.216.722 2.513.738 3.83.764.171 1.522.427 2.262.772.807.376 1.547.838 2.214 1.37zm-4.64-.124a9.048 9.048 0 013.731 1.971 8.995 8.995 0 01-6.993 4.116 10.97 10.97 0 002.393-3.33c.419-.899.706-1.825.87-2.757zm-1.845-2.273a9.025 9.025 0 00-.495-2.581A10.975 10.975 0 013.366 9.45 9.002 9.002 0 003 12a8.96 8.96 0 001.668 5.22 11.017 11.017 0 019.328-4.598zM6.047 18.75a9.01 9.01 0 017.811-4.13 9.018 9.018 0 01-.699 2.186 8.958 8.958 0 01-3.485 3.89 8.98 8.98 0 01-3.627-1.946zM12 3c1.785 0 3.448.52 4.847 1.415a8.944 8.944 0 01-2.479 2.816 10.941 10.941 0 00-4.341-4.014A9.031 9.031 0 0112 3zM8.806 4.846a8.958 8.958 0 013.832 3.39 8.979 8.979 0 01-7.439-.077 9.084 9.084 0 01-1.044-.573 9.044 9.044 0 013.172-3.28c.5.135.994.314 1.48.54z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgDribble;
