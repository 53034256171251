import * as React from 'react';

function SvgPinterest(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.397 2C6.915 2 4 5.513 4 9.343c0 1.776.993 3.991 2.581 4.694.242.108.373.062.427-.162.047-.17.256-.988.357-1.374a.353.353 0 00-.085-.348c-.527-.61-.946-1.721-.946-2.764 0-2.671 2.124-5.265 5.738-5.265 3.125 0 5.311 2.03 5.311 4.934 0 3.281-1.736 5.551-3.993 5.551-1.248 0-2.178-.98-1.883-2.192.356-1.444 1.055-2.997 1.055-4.038 0-.934-.528-1.706-1.605-1.706-1.272 0-2.303 1.259-2.303 2.949 0 1.073.38 1.798.38 1.798s-1.258 5.08-1.491 6.03c-.395 1.606.053 4.207.092 4.43.024.125.163.163.24.062.124-.162 1.644-2.331 2.07-3.899.155-.571.792-2.887.792-2.887.418.756 1.627 1.39 2.915 1.39 3.83 0 6.598-3.367 6.598-7.544C20.236 4.996 16.808 2 12.397 2z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgPinterest;
