import * as React from 'react';

function SvgLastfm(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.915 11.129l-.509-.169c-1.245-.406-1.995-.652-1.995-1.659 0-.816.606-1.407 1.441-1.407.64 0 1.118.276 1.546.899.04.057.116.078.177.045l1.256-.667a.137.137 0 00.07-.087.162.162 0 00-.011-.114c-.674-1.242-1.644-1.846-2.968-1.846-2.013 0-3.316 1.266-3.316 3.225 0 2.002 1.26 2.814 3.583 3.607 1.345.467 1.941.713 1.941 1.708 0 1.119-.971 1.922-2.296 1.875-1.389-.049-1.809-.813-2.338-2.065a595.398 595.398 0 01-1.922-4.62C12.553 7.404 10.526 6 8.015 6 4.699 6 2 8.804 2 12.251c0 3.445 2.699 6.25 6.015 6.25 1.809 0 3.505-.833 4.652-2.287a.15.15 0 00.02-.15l-.757-1.818a.144.144 0 00-.124-.087.138.138 0 00-.128.077c-.717 1.422-2.12 2.304-3.663 2.304-2.276 0-4.128-1.924-4.128-4.29S5.74 7.96 8.015 7.96c1.657 0 3.175 1.021 3.779 2.546l1.876 4.444.216.499c.848 2.051 2.094 2.971 4.045 2.979 2.32 0 4.069-1.598 4.069-3.715 0-2.124-1.131-2.922-3.085-3.584z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgLastfm;
